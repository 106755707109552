import React from "react";
import Modal from '../../common/modal';

const Tutorial = ({ subLink }) => {
    return (
        <Modal target={"windows"} title={"使用教程"}>
            <main className="py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <section className="mb-12">
                        <p className="text-xl text-gray-700 mb-12 text-center">
                            按照以下步骤在 Windows 上设置并使用。
                        </p>
                        <div className="grid grid-cols-1 gap-8 mb-12">
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 1: 下载</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    下载并安装 Clash Verge。
                                </p>
                                <a href="https://clash.run/d/Clash.Verge_1.7.5_x64-setup.exe" target="_blank" className="text-blue-500 hover:underline">
                                    下载链接1
                                </a>
                                <a href="https://ghfast.top/https://github.com/clash-verge-rev/clash-verge-rev/releases/download/v2.0.1/Clash.Verge_2.0.1_x64-setup.exe" target="_blank" className="ml-1 text-blue-500 hover:underline">
                                    下载链接2
                                </a>
                                <a href="https://ghfast.top/https://github.com/zzzgydi/clash-verge/releases/download/v1.3.8/Clash.Verge_1.3.8_x64-setup.exe" target="_blank" className="ml-1 text-blue-500 hover:underline">
                                    下载链接3(适配win7)
                                </a>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 2: 安装</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    打开下载的安装程序，按照提示安装 Clash Verge。
                                </p>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 3: 导入</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    <button
                                        onClick={() => {
                                            let encodeurl = encodeURIComponent(subLink);
                                            window.location.href = `clash://install-config?url=${encodeurl}&name=${encodeURIComponent(process.env.REACT_APP_NAME)}`;
                                        }}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        一键导入节点
                                    </button>
                                </p>
                            </div>
                            <div className="bg-blue-100 p-6 rounded-lg">
                                <h2 className="text-2xl font-bold mb-4">步骤 4: 运行</h2>
                                <p className="text-lg text-gray-700 mb-4">
                                    <a href="https://i0.wp.com/clash.run/wp-content/uploads/2024/10/image-7.png?w=999&ssl=1" target="_blank"><img src="https://i0.wp.com/clash.run/wp-content/uploads/2024/10/image-7.png?w=999&ssl=1" alt="Clash Verge" /></a>
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </Modal>
    );
};

export default Tutorial;